<template>
  <div class="flex flex-row">
    <template
      v-for="device in devices"
      :key="device.id"
    >
      <div class="max-w-sm rounded overflow-hidden shadow-lg bg-urbslate m-2">
        <div class="px-6 py-4">
          <template v-if="!device.editAppId">
            <div
              class="font-bold text-xl mb-2 text-urbtext"
              @dblclick="() => {device.editAppId = true}"
            >
              {{ device.device_app_id }}
            </div>
          </template>
          <template v-else>
            <InputText
              v-model="device.device_app_id"
              type="text"
              class="font-bold text-xl mb-2 text-center py-0"
              @change="() => { device.saved = false }"
            />
          </template>
          <template
            v-if="!device.editName"
          >
            <div
              v-if="device.device_name"
              class="font-bold text-m mb-2 text-urbtext"
              @dblclick="() => {device.editName = true}"
            >
              {{ device.device_name }}
            </div>
          </template>
          <template v-else>
            <InputText
              v-model="device.device_name"
              type="text"
              class="font-bold text-m mb-2 text-center py-0"
              @change="() => { device.saved = false }"
            />
          </template>
          <p class="text-urbnavy text-base">
            Sites loaded: 
            <template v-if="device.settings.split_sites.value">
              {{ device.site_count }}
            </template>
            <template v-else>
              All
            </template>
          </p>
        </div>
        <Dialog
          v-model:visible="device.showDeviceSettings"
          :header="`Settings for ${device.device_app_id} (${device.device_name})`"
          :keepInViewport="true"
        >
          Split sites
          <SelectButton
            v-model="device.settings.split_sites"
            :options="[
              { name: 'Split', value: 1}, 
              { name: 'All', value: 0}, 
            ]"
            optionLabel="name"
            dataKey="value"
            @change="changeSetting($event, device)"
          />
        </Dialog>
        <Dialog
          v-model:visible="device.showSites"
          :header="`Sites for ${device.device_app_id} (${device.device_name})`"
          :keepInViewport="false"
        >
          <template v-if="device.sites">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="device.site_search"
                type="text"
                placeholder="Search"
              />
            </span>
            <template
              v-for="site in device.sites"
              :key="site.id"
            >
              <div
                v-if="matchesSiteSearch(site.site_name, device.site_search)"
                class="flex flex-row justify-between"
              >
                {{ site.site_name }}
                <template v-if="site.attached">
                  <Button
                    class="p-button-danger"
                    @click="detachSite(device, site)"
                  >
                    <i class="pi pi-minus-circle" />
                  </Button>
                </template>
                <template v-else>
                  <Button
                    class="p-button-success"
                    @click="attachSite(device, site)"
                  >
                    <i class="pi pi-plus-circle" />
                  </Button>
                </template>
              </div>
            </template>
          </template>
          <template v-else>
            SITES LOADING OR SOMETHING
          </template>
        </Dialog>
        <div class="px-6 pb-2">
          <div>
            <i
              v-tooltip="!device.saved ? 'Save' : false"
              class="pi pi-save text-3xl text-urbnavy p-4 "
              :class="{
                'cursor-pointer hover:opacity-50': !device.saved,
                'opacity-25': device.saved,
              }"
              @click="saveChanges(device)"
            />
            <i
              v-tooltip="'Generate Lookups'"
              :class="{
                'pi pi-compass text-3xl text-green-500 p-4 cursor-pointer hover:opacity-50': !device.lookupLoading,
                'pi pi-spin pi-spinner text-3xl text-green-500 p-4 cursor-pointer hover:opacity-50': device.lookupLoading,
              }"
              @click="generateLookups(device)"
            />
            <i
              class="pi pi-map-marker text-3xl text-urborange p-4 cursor-pointer hover:opacity-50"
              @click="showDeviceSites(device)"
            />
            <i
              class="pi pi-cog text-3xl text-urbtext p-4 cursor-pointer hover:opacity-50"
              @click="showDeviceSettings(device)"
            />
            <i
              class="pi pi-trash text-3xl text-red-600 p-4 opacity-25"
            />
          </div>
        </div>
      </div>
    </template>
    <i
      class="pi pi-plus text-6xl text-urbtext self-center cursor-pointer"
      @click="newDevice()"
    />
  </div>
</template>

<script>
import DeviceService from '../services/DeviceProvider';
import Dialog from 'primevue/dialog';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';


export default {
	components: {
		Dialog,
		TriState: TriStateCheckbox,
		SelectButton
	},
	data(){
		return {
			devices: []
		};
	},
	mounted(){
		DeviceService.getAllDevices()
			.then((res) => {
				res.data.devices.forEach((device) => {
					device.showSites = false;
					device.showDeviceSettings = false;
					device.editAppId = false;
					device.editName = false;
					device.saved = true;
					device.site_count = (device.site_count == null) ? 0 : device.site_count;
					device.sites = [];
					device.lookupLoading = false;
					device.site_search = '';
					device.settings = {
						split_sites: {
							value:  device.split_sites
						}
					};
					console.log(device);
				});
				this.devices = res.data.devices;
			});
	},
	methods: {
		showDeviceSites(device){
			console.log(device);
			device.showSites = true;
			DeviceService.getDeviceSites(device.device_id)
				.then((res) => {
					res.data.sites.forEach((site) => {
						site.loading = false;
					});
					device.sites = res.data.sites;
				});
		},
		attachSite(device, site){
			DeviceService.attachSite(device.device_id, site.id)
				.then((res) => {
					site.attached = 1;
					device.site_count++;
				});
		},
		detachSite(device, site){
			DeviceService.detachSite(device.device_id, site.id)
				.then((res) => {
					site.attached = 0;
					device.site_count--;
				});
		},
		generateLookups(device){
			device.lookupLoading = true;
			DeviceService.generateLookups(device.device_id)
				.then((res) => {
					if (res.data.form_count > 0 ){
						this.$toast.add({
							severity: 'success', 
							summary: res.data.form_count + ' forms successfully generated.', 
							detail: res.data.message
						});

					} else {
						this.$toast.add({
							severity: 'warn', 
							summary: 'No forms generated.', 
							detail: res.data.message
						});
					}
				})
				.catch((err) => {
					this.$toast.add({
						severity: 'error', 
						summary: 'An error has occured!', 
						detail: err.message
					});
				})
				.finally(() => { device.lookupLoading = false; });
		},
		matchesSiteSearch(site_name, search_term){
			return site_name.toUpperCase().includes(search_term.toUpperCase());
		},
		newDevice(){
			this.devices.push(this.devices[0]);
		},
		showDeviceSettings(device){
			device.showDeviceSettings = true;
		},
		changeSetting(event, device){
			DeviceService.modifySetting(device.device_id, event.value.value)
				.then((res) => {
					console.log(res.data.message);
				});
		},
		saveChanges(device){
			if (device.saved){
				return;
			}

      
		}
	},
	
};
</script>

<style>

</style>